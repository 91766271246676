import BreifZanskar from "./breifZanskar";
import DetailsZanskar from "./detailsZanskar";
import HeroZanskar from "./heroZanskar";
// import ZanskarGallery from "./zanskarGallery";
import ZanskarWeather from "./zanskarWeather";

export default function Zanskar() {
	return (
		<div className="bg-white dark:bg-gray-800">
			<HeroZanskar />
			<BreifZanskar />
			<ZanskarWeather />
			<DetailsZanskar />
			{/* <ZanskarGallery /> */}
		</div >

	)
}

