import UNDER from "../images/under.png"

export default function UnderDevelopment() {
    return (
        <div class="h-screen w-screen bg-gray-100 dark:bg-gray-700 flex items-center">
            <div class="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                <div class="max-w-md">
                    <p
                        class="text-2xl dark:text-white md:text-3xl font-light leading-normal"
                    >Sorry but this page is taking longer than expected to construct.</p>
                    <p class="mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>
                    <a href="/">
                        <button className="px-8 py-3 m-2 text-lg font-semibold rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200 dark:text-yellow-700">Back to Homepage</button>
                    </a>
                </div>
                <div class="max-w-lg">
                    <img src={UNDER} alt="under" />
                </div>

            </div>
        </div>
    )
}