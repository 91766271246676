
export default function HeroZanskar() {


    return (
        <div className="w-full bg-cover bg-center h-96" style={{
            backgroundImage: "url(https://images.pexels.com/photos/6492212/pexels-photo-6492212.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)"
        }}>
            <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
                <div className="text-center">
                    <h1 className="text-white text-2xl font-semibold uppercase md:text-6xl"><span className="text-yellow-400">Zanskar Expedition</span> with Leh</h1>
                    <a href="https://drive.google.com/uc?export=download&id=1A3Rt2HlvBwXNzKpyGVSfyWHMHGinD4G_" target="_blank" rel="noreferrer">
                        <button className="px-8 py-3 mt-10 text-lg font-semibold rounded hover:bg-yellow-300 hover:text-yellow-800 bg-yellow-400 text-yellow-900">Get Itinerary</button>
                    </a>
                    <a href='https://pmny.in/kIDNGLn5O6Qm'>
                        <button className="ml-12 px-8 py-3 mt-10 text-lg font-semibold rounded hover:bg-yellow-300 hover:text-yellow-800 bg-yellow-400 text-yellow-900">Book Now</button>
                    </a>
                </div>
            </div>
        </div>
    )
}