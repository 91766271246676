const itineraryData = [
    {
        date: '13',
        day: 'Saturday',
        destination: 'Manali',
    },
    {
        date: '14',
        day: 'Sunday',
        destination: 'Manali to Jispa',
    },
    {
        date: '15',
        day: 'Monday',
        destination: 'Jispa to Gonbo Rangjon',
    },
    {
        date: '16',
        day: 'Tuesday',
        destination: 'Gonbo Rangjon to Purne',
    },
    {
        date: '17',
        day: 'Wednesday',
        destination: 'Purne to Padum',
    },
    {
        date: '18',
        day: 'Thursday',
        destination: 'Padum to Lamayuru',
    },
    {
        date: '19',
        day: 'Friday',
        destination: 'Lamayuru to Leh',
    },
    {
        date: '20',
        day: 'Saturday',
        destination: 'Leh Local',
    },
    {
        date: '21',
        day: 'Sunday',
        destination: 'Leh to Manali',
    },
]

export default function ItineraryZanskar() {
    return (
        <div className="dark:bg-gray-800">
            <div className="w-full mx-auto space-y-4 text-center mb-8">
                <h1 className="text-4xl font-bold leading-tight text-yellow-300 md:text-5xl">Breif Itinerary</h1>
                <span className="text-xl leading-relaxed text-black dark:text-white">8 Days & 9 Nights</span>
            </div>
            <div class="grid md:grid-cols-4 grid-cols-2 place-items-center">
                {itineraryData.map((iData) => (
                    <div class="min-w-32 bg-white dark:bg-gray-800 min-h-48 p-3 mb-4 font-medium" key={iData.date}>
                        <div class="w-32 flex-none rounded-t lg:rounded-t-none lg:rounded-l text-center shadow-lg ">
                            <div class="block rounded-t overflow-hidden  text-center ">
                                <div class="bg-yellow-300 text-white py-1">
                                    August
                                </div>
                                <div class="pt-1 border-l border-r border-white bg-white ">
                                    <span class="text-5xl font-bold leading-tight text-black">
                                        {iData.date}
                                    </span>
                                </div>
                                <div class="border-l border-r border-b rounded-b-lg text-center border-white bg-white -mb-1 pb-2">
                                    <span class="text-xs text-black">
                                        {iData.day}
                                    </span>
                                </div>
                                <div class="pb-2 border-l border-r border-b rounded-b-lg text-center border-white bg-yellow-600 ">
                                    <span class="text-xs dark:text-white text-yellow-300 font-semibold leading-normal">
                                        {iData.destination}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full mx-auto space-y-4 text-center mb-8">
                <a href="https://drive.google.com/uc?export=download&id=1A3Rt2HlvBwXNzKpyGVSfyWHMHGinD4G_" target="_blank" rel="noreferrer">
                    <button className="px-8 py-3 mt-10 text-lg font-semibold rounded hover:bg-yellow-300 hover:text-yellow-800 bg-yellow-400 text-yellow-900">Download Detailed Itinerary</button>
                </a>
            </div>
        </div>
    )
}