// Mid Section CTA one

export default function CtaHomeOne() {
    return (

        <div className="bg-white dark:bg-gray-800 ">
            <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                    <span className="block">
                        Explore Spiti this summer
                    </span>
                    <span className="block text-yellow-500">
                        It&#x27;s now or never.
                    </span>
                </h2>
                {/* <p className="text-xl mt-4 max-w-md mx-auto text-gray-400">
            I had noticed that both in the very poor and very rich extremes of society the mad were often allowed to mingle freely
        </p> */}
                <div className="lg:mt-0 lg:flex-shrink-0">
                    <div className="mt-12 inline-flex rounded-md shadow">
                        <a
                            href="/spiti"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
                        >
                            Get Spitified
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}