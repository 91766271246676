import ContactHero from "../components/contact/contactHero";
import ContactPageForm from "../components/contact/contactPageForm";

export default function Contact() {
    return (
        <div>
            <ContactHero />
            <ContactPageForm />
        </div>
    )
}