import FeatureTrip from "../featureTrip";
import UpcomingTour from "../upcomingTour";
import TourHero from "./tourHero";

export default function Tours() {
    return (
        <div className="dark:bg-gray-800">
            <TourHero />
            <FeatureTrip />
            <UpcomingTour />
        </div>
    )
}