export default function TourHero() {
    return (
        <section class="relative bg-white dark:bg-gray-800">
            <img
                class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-25 sm:opacity-100"
                src="https://images.pexels.com/photos/7175000/pexels-photo-7175000.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Couple on a bed with a dog"
            />

            <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r dark:sm:from-gray-800 sm:from-white sm:to-transparent"></div>

            <div class="relative max-w-screen-xl px-4 py-32 mx-auto lg:h-screen lg:items-center lg:flex">
                <div class="max-w-xl text-center sm:text-left">
                    <h1 class="text-3xl font-extrabold sm:text-5xl dark:text-white">
                        Providing Unreal Travel Experiences <strong class="font-extrabold text-yellow-400 sm:block">
                            Like Never Before.
                        </strong>
                    </h1>

                    <p class="max-w-lg mt-4 sm:leading-relaxed sm:text-xl dark:text-white">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!
                    </p>

                    <div class="  gap-4 mt-8">
                        <a
                            href="/wait"
                            className="inline-block text-center bg-yellow-100 border border-transparent rounded-md py-3 px-8 font-medium text-yellow-600 hover:bg-yellow-200"
                        >
                            Explore!
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}