// Explore Our Stays Hero homepage section

import IMG1 from '../images/stays/jichun.jpg'
import IMG2 from '../images/stays/tanpalacamps.jpeg'
import IMG3 from '../images/stays/dikitling.jpg'
import IMG4 from '../images/stays/13forest.jpg'
import IMG5 from '../images/stays/treelinecottage.jpg'
import IMG6 from '../images/stays/vishranti.jpg'
import IMG7 from '../images/stays/dhankar.jpg'

export default function HotelHero() {
  return (
    <div className="max-w-screen-xl p-4 bg-white dark:bg-gray-800 mx-auto px-4 sm:px-6 lg:px-8 relative py-26 lg:mt-20">
      <div className="relative">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2 lg:max-w-2xl ml-auto">
            <p className="text-base leading-6 text-yellow-500 font-semibold uppercase">
              Summer Homes!
            </p>
            <h4 className="mt-2 text-2xl leading-8 font-extrabold text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
              This year, our new summer home collection will shelter you from the harsh elements of the world.
            </h4>
            <p className="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
              We hand pick our stays at various locations for our fellow travellers and tourists.            </p>
            <a
              href="/stay"
              className="inline-block text-center bg-yellow-100 border border-transparent rounded-md py-3 px-8 mt-8 font-medium text-yellow-600 hover:bg-yellow-200"
            >
              Take me there!
            </a>
          </div>
          <div className="mt-10 lg:-mx-4 relative relative-20 lg:mt-0 lg:col-start-1">
            <div className="relative space-y-4">
              <div className="flex items-end justify-center lg:justify-start space-x-4">
                <img className="rounded-lg shadow-lg w-32 md:w-56" width="200" src={IMG1} alt="1" />
                <img className="rounded-lg shadow-lg w-40 md:w-64" width="260" src={IMG2} alt="2" />
              </div>
              <div className="flex items-start justify-center lg:justify-start space-x-4 ml-12">
                <img className="rounded-lg shadow-lg w-34 md:w-52" width="170" src={IMG3} alt="3" />
                <img className="rounded-lg shadow-lg w-32 md:w-56" width="200" src={IMG4} alt="4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}