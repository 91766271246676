import { useState } from 'react'
import { Tab } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DetailsSpiti() {
    let [categories] = useState({
        
        Costing: [
            {
                id: 1,
                title: '',
                desc:
                    <div>
                        <div className="w-full mx-auto space-y-4 text-center mb-8">
                            <h1 className="text-4xl font-bold leading-tight text-yellow-300 md:text-5xl">Contact us for Customised tours</h1>
                            <div className="text-xl leading-relaxed text-black dark:text-white">Mode of travel: <span className='text-yellow-300'>SUV 4x4, SUV 4x2, Tempo Traveller, Sedan, etc.</span></div>
                            <a href="/contact" target="_blank" rel="noreferrer">
                                <button className="px-8 py-3 mt-10 mb-8 text-lg font-semibold rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200">Contact Us</button>
                            </a>
                            <div className='md:flex md:flex-row border-t border-yellow-300 pt-8'>

                                <div className='md:w-1/2 w-full dark:text-white text-black text-left'>
                                    <div className='text-4xl  text-yellow-300'>Inclusions</div>
                                    <ul className='list-decimal mt-4 font-normal'>
                                        <li>Entire travel from Point A to Point B by various travel modes</li>
                                        <li>Stay for all the nights</li>
                                        <li>Meals - Breakfast & Dinner</li>
                                        <li>All inner line permits for the trip if required.</li>
                                        <li>Driver Night Charges, Toll Tax, Parking Charges, etc.</li>
                                        <li>An Oxygen Cylinder 24x7 in the vehicle in case of emergency</li>
                                    </ul>
                                </div>

                                <div className='md:w-1/2 w-full dark:text-white text-black text-left'>
                                    <div className='text-4xl  text-yellow-300'>Exclusions</div>
                                    <ul className='list-decimal mt-4 font-normal'>
                                        <li>Any kind of food or beverage that is not included in the package like alcoholic drinks, mineral water, meals/refreshments/lunches on the highway.</li>
                                        <li>Any personal expenses like a tip to the drivers, entry to monuments/monasteries, camera/video camera charges, camel safari, river rafting, laundry, telephone bills, tips, etc</li>
                                        <li>Any cost arising due to natural calamities like landslides, roadblocks etc. (to be borne directly by the customer on the spot)</li>
                                        <li>Anything not mentioned in the inclusions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

            },
        ],
        More: [
            {
                id: 2,
                title: '',
                desc:
                    <div>
                        <div className="w-full mx-auto space-y-4 text-center mb-8">
                            <h1 className="text-4xl font-bold leading-tight text-yellow-300 md:text-5xl">Other Info</h1>

                            <div className='md:flex md:flex-row border-t border-yellow-300 pt-8'>

                                <div className='md:w-1/2 w-full dark:text-white text-black text-left'>
                                    <div className='text-4xl  text-yellow-300'>Must Carry
                                    </div>
                                    <ul className=' list-disc mt-4 font-normal'>
                                        <li>Authentic Government ID Card</li>
                                        <li>Comfortable warm clothing like woolen socks, cap, fleece jackets or warmers, down jacket, toiletries.</li>
                                        <li>Sunscreen & lip balm, Good U/V protection sunglasses</li>
                                        <li>Personal Medicines (if any)</li>
                                    </ul>
                                </div>

                                <div className='md:w-1/2 w-full dark:text-white text-black text-left'>
                                    <div className='text-4xl text-yellow-300 mb-4'>Cancellation Policy
                                    </div>
                                    <span className='font-extrabold '>No Refund shall be made with respect to the initial booking amount for any cancellations. However,</span>

                                    <ul className='list-disc mt-1 font-normal'>
                                        <li>If cancellations are made 30 days before the start date of the trip, 50% of the trip cost will be charged as cancellation fees.</li>
                                        <li>If cancellations are made 15-30 days before the start date of the trip, 75% of the trip cost will be charged as cancellation fees.</li>
                                        <li>If cancellations are made within 0-15 days before the start date of the trip, 100% of the trip cost will be charged as cancellation fees.</li>
                                        <li>In the case of unforeseen weather conditions or government restrictions, certain activities may be cancelled and in such cases, the operator will try his best to provide an alternate feasible activity. However, no refund will be provided for the same.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

            },
        ],
    })

    return (
        <div className='bg-white dark:bg-gray-800 '>
            <div className='mx-20 '>
                <div className="w-full px-2 py-16 sm:px-0 dark:bg-gray-800 bg-white">
                    <Tab.Group>
                        <Tab.List className="flex space-x-1 rounded-xl  bg-gray-500/20 dark:bg-gray-300/20 p-1">
                            {Object.keys(categories).map((category) => (
                                <Tab
                                    key={category}
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-yellow-800',
                                            'ring-yellow-200 ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2',
                                            selected
                                                ? 'bg-yellow-200 shadow'
                                                : 'dark:text-blue-100 text-black hover:bg-yellow/[0.12] hover:text-yellow-300'
                                        )
                                    }
                                >
                                    {category}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            {Object.values(categories).map((posts, idx) => (
                                <Tab.Panel
                                    key={idx}
                                    className={classNames(
                                        'rounded-xl bg-white dark:bg-gray-800 p-3',
                                        'ring-yellow-200 ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2'
                                    )}
                                >
                                    <ul className='text-center'>
                                        {posts.map((post) => (
                                            <li
                                                key={post.id}
                                                className="relative rounded-md p-3 "
                                            >
                                                <h3 className="text-sm font-medium leading-5 text-gray-200 ">
                                                    {post.title}
                                                    {post.desc}
                                                </h3>
                                            </li>
                                        ))}
                                    </ul>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    )
}