export default function Refund() {
    return (
        <div className="dark:bg-gray-800 bg-gray-100 text-black dark:text-gray-300">
            <div className="p-20">
                <h1 className="text-center text-4xl my-6 font-semibold ">Refund & Cancellation Policy</h1>
                <p className="text-2xl mb-4">No Refund shall be made with respect to the initial booking amount for any cancellations. However,</p>
                <ul className=" list-decimal mx-10 md:text-base text-xs">
                    <li>Advance payment is only refundable if the tour is cancelled by us 20 days prior to the tour date, after that date, the advance is not refundable</li>
                    <li>Tour package cost is non-refundable if you cancel the tours after reaching the destination or between the tour.</li>
                    <li>In case of bad roads or bad weather conditions, the tour plan will get affected, the cost has to be paid by the guests.</li>
                    <li>If the guest's vehicle breakdowns at the start of the tour or in between the tour, the tour package is non-refundable.</li>
                    <li>The number of participants of the tour increases than the said promised numbers, the additional cost has to be bared by the guests.</li>
                    <li>If the number of participants decreases in between the trip, the tour package will be non-refundable.</li>
                </ul>

            </div>
        </div>
    )
}