export default function UpcomingTour() {
    return (
        <div className="max-w-screen-xl p-5 mx-auto dark:bg-gray-800 text-yellow-300 dark:text-gray-100">
            <div className="text-center w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                    <span className="block">
                        Upcoming Tours and Treks
                    </span>
                    <span className="block text-yellow-500 text-xl mt-2">
                        From the mighty himalayas to endless sea, <br /> explore our upcoming tours to various destinations
                    </span>
                </h2>
            </div>
            <div className="grid gap-5 grid-cols-2 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
                <div className="relative flex items-end justify-start  text-left bg-center bg-cover lg:h-96 md:w-auto md:h-56 h-80 w-44 dark:bg-gray-100 bg-gray-400 bg-blend-multiply" style={{ backgroundImage: `url("https://images.pexels.com/photos/6492212/pexels-photo-6492212.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")` }} >
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b dark:via-transparent dark:from-gray-900 dark:to-gray-900"></div>
                    <div className="absolute top-0 left-0 right-0 flex items-center justify-between mx-5 mt-3">
                        <a rel="noopener noreferrer" href="/zanskar" className="px-3 py-2 text-xs font-semibold tracking-wider uppercase  dark:text-gray-100 bgundefined">Zanskar</a>
                        <div className="flex flex-col justify-start text-center  dark:text-gray-100">
                            <span className="text-3xl font-semibold leading-none tracking-wide">13</span>
                            <span className="leading-none uppercase">Aug</span>
                        </div>
                    </div>
                    <h2 className="z-10 p-5">
                        <a rel="noopener noreferrer" href="/zanskar" className="font-medium text-md hover:underline dark:text-gray-100"> Zanskar Expedition - Survior Series 4.0</a>
                    </h2>
                </div>
                <div className="relative flex items-end justify-start h-80 w-44 text-left bg-center bg-cover sm:h-48 lg:h-96 md:w-auto md:h-56 dark:bg-gray-100 bg-gray-400 bg-blend-multiply" style={{ backgroundImage: `url("https://images.pexels.com/photos/5238634/pexels-photo-5238634.jpeg?auto=compress&cs=tinysrgb&w=600")` }} >
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b dark:via-transparent dark:from-gray-900 dark:to-gray-900"></div>
                    <div className="absolute top-0 left-0 right-0 flex items-center justify-between mx-5 mt-3">
                        <a rel="noopener noreferrer" href="/spiti" className="px-3 py-2 text-xs font-semibold tracking-wider uppercase dark:text-gray-100 bgundefined">Spiti Valley</a>
                        <div className="flex flex-col justify-start text-center  dark:text-gray-100">
                            <span className="text-3xl font-semibold leading-none tracking-wide">TBD</span>
                            {/* <span className="leading-none uppercase">Aug</span> */}
                        </div>
                    </div>
                    <h2 className="z-10 p-5">
                        <a rel="noopener noreferrer" href="/spiti" className="font-medium text-md hover:underline  dark:text-gray-100">Explore the best of Spiti by visiting places less travelled.</a>
                    </h2>
                </div>
                <div className="relative flex items-end justify-start h-80 w-44 text-left bg-center bg-cover sm:h-48 lg:h-96 md:w-auto md:h-56 dark:bg-gray-100 bg-gray-400 bg-blend-multiply" style={{ backgroundImage: `url("https://images.pexels.com/photos/5015055/pexels-photo-5015055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")` }}>
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b dark:via-transparent dark:from-gray-900 dark:to-gray-900"></div>
                    <div className="absolute top-0 left-0 right-0 flex items-center justify-between mx-5 mt-3">
                        <a rel="noopener noreferrer" href="/zanskar" className="px-3 py-2 text-xs font-semibold tracking-wider uppercase  dark:text-gray-100 bgundefined">Ladakh </a>
                        <div className="flex flex-col justify-start text-center dark:text-gray-100">
                            <span className="text-3xl font-semibold leading-none tracking-wide">TBD</span>
                            {/* <span className="leading-none uppercase">Jul</span> */}
                        </div>
                    </div>
                    <h2 className="z-10 p-5">
                        <a rel="noopener noreferrer" href="/zanskar" className="font-medium text-md hover:underline  dark:text-gray-100">Visit Ladakh and Zanskar this summer.</a>
                    </h2>
                </div>
                <div className="relative flex items-end justify-start h-80 w-44 text-left bg-center bg-cover sm:h-auto sm:w-4 lg:h-96 md:w-auto md:h-56 dark:bg-gray-100 bg-gray-400 bg-blend-multiply" style={{ backgroundImage: `url("https://images.pexels.com/photos/4393599/pexels-photo-4393599.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")` }} >
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b dark:via-transparent dark:from-gray-900 dark:to-gray-900"></div>
                    <div className="absolute top-0 left-0 right-0 flex items-center justify-between mx-5 mt-3">
                        <a rel="noopener noreferrer" href="/spiti" className="px-3 py-2 text-xs font-semibold tracking-wider uppercase  dark:text-gray-100 bgundefined">Spiti Valley</a>
                        <div className="flex flex-col justify-start text-center  dark:text-gray-100">
                            <span className="text-3xl font-semibold leading-none tracking-wide">TBD</span>
                            {/* <span className="leading-none uppercase">Jul</span> */}
                        </div>
                    </div>
                    <h2 className="z-10 p-5">
                        <a rel="noopener noreferrer" href="/spiti" className="font-medium text-md hover:underline dark:text-gray-100">Explore the best of Spiti by visiting places less travelled.</a>
                    </h2>
                </div>
            </div>
            <div className="lg:mt-0 lg:flex-shrink-0 text-center w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                <div className=" inline-flex rounded-md shadow">
                    <a
                        href="/tours"
                        className="w-full flex items-center justify-center  px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
                    >
                        View More!
                    </a>
                </div>
            </div>
        </div >
    )
}