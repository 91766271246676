// Explore Our treks Hero homepage section

export default function TrekHero() {
    return (
      <div className="relative bg-white overflow-hidden dark:bg-gray-800 m-8">
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font font-extrabold tracking-tight text-yellow-600 sm:text-6xl">
              Adventure across the Western Ghats
              </h1>
              <p className="mt-4 text-xl text-gray-500 dark:text-white">
              Get curated treks and tours for you and your friends across the western ghats, from wide range of treks to secluded stays we've got you covered for your next adventure.
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className=" flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-36 h-48 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <img
                            src="https://media.istockphoto.com/photos/young-woman-enjoying-fresh-air-near-stream-in-pine-jungle-picture-id928252416?b=1&k=20&m=928252416&s=170667a&w=0&h=_OfmAkG6bU3S1irDL4zlCMgvM80XaO0UVNHQWFGlcgM="
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1625979061911-e3673d9a5385?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fHdhdGVyZmFsbCUyMGluZGlhfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1504626348080-fd59c115dc69?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTM4fHxpbmRpYSUyMHRyZWtzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1617284101128-4b2025d00c55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTR8fGluZGlhJTIwdHJla3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1620766165457-a8025baa82e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8a2FybmF0YWthfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1607543024015-8b6986aa13de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjEyfHxrYXJuYXRha2F8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-36 h-48 rounded-lg overflow-hidden">
                          <img
                            src="https://images.unsplash.com/photo-1640733916595-4024ccf32c84?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDQzfHxrYXJuYXRha2F8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <a
                  href="/wait"
                  className="inline-block text-center bg-yellow-100 border border-transparent rounded-md py-3 px-8 font-medium text-yellow-600 hover:bg-yellow-200"
                >
                  Let's Trek!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }