
export default function AboutHero() {
    return (
        <div className="w-full bg-cover bg-center h-96" style={{
            backgroundImage: "url(https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)"
        }}>
            <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
                <div className="text-center">
                    <h1 className="text-white text-2xl font-semibold uppercase md:text-6xl"><span className="text-yellow-400">About</span> Us</h1>

                </div>
            </div>
        </div>
    )
}