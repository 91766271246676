import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ContactWidget from './components/contactWidget';
import Footer from './components/footer';
import Nav from './components/nav';
import Contact from './pages/contact';
import Home from './pages/home';
import Stay from './pages/stay';
import Zanskar from './components/tours/zanskar/zanskar';
import Spiti from './components/tours/spiti/spiti';
import Tours from './components/tours/tours';
import About from './components/about/about';
import UnderDevelopment from './pages/underDevelopment';
import Privacy from './pages/privacy';
import Refund from './pages/refund';
import Terms from './pages/terms';
import Disclaimer from './pages/disclaimer';

function App() {
  return (
    <div>
      <Nav />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='contact' element={<Contact />} />
          <Route path='tours' element={<Tours />} />
          <Route path='stay' element={<Stay />} />
          <Route path='about' element={<About />} />
          <Route path='zanskar' element={<Zanskar />} />
          <Route path='spiti' element={<Spiti />} />

          <Route path='privacy' element={<Privacy />} />
          <Route path='refund' element={<Refund />} />
          <Route path='terms' element={<Terms />} />
          <Route path='disclaimer' element={<Disclaimer />} />
          <Route path='wait' element={<UnderDevelopment />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <ContactWidget />
    </div>
  );
}

export default App;
