import BreifSpiti from "./breifSpiti";
import DetailsSpiti from "./detailsSpiti";
import HeroSpiti from "./heroSpiti";
import SpitiWeather from "./spitiWeather";

export default function Spiti() {
	return (
		<div className="bg-white dark:bg-gray-800">
			<HeroSpiti />
			<BreifSpiti />
			<SpitiWeather />
			<DetailsSpiti />
		</div >

	)
}

