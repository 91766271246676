// import StayGrid from "../components/stay/stayGrid";
import StayHero from "../components/stay/stayHero";
import StayList from "../components/stay/stayList";

export default function Stay() {
    return (
        <div className="dark:bg-gray-800">
            <StayHero />
            {/* <StayGrid /> */}
            <StayList />
        </div>
    )
}