import RAJ from "../../images/me.jpg"
import ANIL from "../../images/anil.jpg"
import TENZIN from "../../images/tenzin.jpg"
import TASHI from "../../images/tashi.jpg"
import AGNISH from "../../images/agnish.jpg"
import PALVAR from "../../images/palvar.jpg"

const teamData = [
    {
        name: "Prajwal Raj",
        position: "CEO - Founder",
        picture: RAJ,
    },
    {
        name: "Tenzin Minguer",
        position: "Hospitality Head - Spiti",
        picture: TENZIN,
    },
    {
        name: "Anil Thakur",
        position: "Operations Head - Spiti",
        picture: ANIL,
    },
    {
        name: "Tashi Miyaas",
        position: "Hospitality Head - Spiti ",
        picture: TASHI,
    },
    {
        name: "Agnish Rai",
        position: "Hospitality Manager - Key Monastery",
        picture: AGNISH,
    },
    {
        name: "Tenzin Palvar",
        position: "Hospitality Manager - Kibber",
        picture: PALVAR,
    },
]



export default function Team() {
    return (

        <div className="py-20 bg-white dark:bg-gray-800">
            <div className="container mx-auto px-6 md:px-12">
                <div className="mb-16 text-center">
                    <h2 className="mb-4 text-center text-2xl text-yellow-400 font-bold md:text-4xl">Who are we?</h2>
                    <p className="dark:text-white text-justify text-black lg:w-8/12 lg:mx-auto">Unreal Journey is a offbeat travel and hospitality company that promotes expirential experiences. Our services include road trips, trekking expeditions, corporate trips, and customized tour packages. On our trips, we ensure hassle-free traveling, top-notch accommodation and guided sightseeing that too in a budget that won’t burn a hole in your pocket. Just give us your dates and be ready to experience traveling like never before. Not only tours but we also pride our very own unique stays and experiences that make it possible for you to connect with communities in a more authentic way. </p>
                </div>
                <div className="mb-16 text-center">
                    <h2 className="mb-4 text-center text-2xl text-yellow-400 font-bold md:text-4xl">Our Team</h2>
                    <p className="dark:text-white text-justify text-black lg:w-8/12 lg:mx-auto">Unreal Journey prides itself not only on providing out of the world hospitality and expirential travels, but also on the talent of its people of some of the brightest minds and most experienced executives in business.</p>
                </div>
                <div className="grid gap-24 md:gap-12 md:grid-cols-3 grid-cols-1">
                    {teamData.map((tdata) => (
                        <div className="space-y-4 text-center group">
                            <div className="w-36 h-36 mx-auto rounded-[4rem] rotate-45 overflow-hidden md:w-40 md:h-40 lg:w-56 lg:h-56">
                                <img className="w-full h-full object-cover -rotate-45 scale-125 mx-auto transition duration-300 group-hover:scale-[1.4]"
                                    src={tdata.picture} alt="Prajwal" loading="lazy" width="640" height="805" />
                            </div>
                            <div className="pt-4">
                                <h4 className="text-2xl text-yellow-300">{tdata.name}</h4>
                                <span className="block text-sm text-black dark:text-white">{tdata.position}</span>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}