import AboutHero from "./aboutHero";
import Stats from "./stats";
import Team from "./team";

export default function About() {
    return (
        <div>
            <AboutHero />
            <Team />
            <Stats />
        </div>
    )
}