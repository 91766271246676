import CtaHomeOne from "../components/ctaHomeOne";
import CtaTicket from "../components/ctaTicket";
import ExploreCard from "../components/exploreCard";
import FeatureTrip from "../components/featureTrip";
import Hero from "../components/hero";
import HotelHero from "../components/hotelHero";
// import TourList from "../components/tourList";
import TrekHero from "../components/trekHero";
import UpcomingTour from "../components/upcomingTour";

export default function Home() {
    return (
        <div className="bg-white dark:bg-gray-800">
            <Hero />
            <ExploreCard />
            <CtaHomeOne />
            <FeatureTrip />
            {/* <TourGrid /> */}
            <TrekHero />
            {/* <TourList /> */}
            <CtaTicket />
            <HotelHero />
            <UpcomingTour />
            {/* <OtaHomeTwo /> */}
            {/* <Quote /> */}
        </div>
    )
}