export default function BreifSpiti() {
    return (
        <article className="mx-auto px-6 py-24 space-y-12 dark:bg-gray-800 dark:text-gray-50">
            <div className="w-full mx-auto space-y-4 text-center">
                <p className="text-xs font-semibold tracking-wider uppercase">Spiti Expedition</p>
                <h1 className="text-4xl font-bold leading-tight md:text-5xl text-yellow-300">Lets explore the Unreal Middle Lands</h1>

            </div>
            <div className="dark:text-gray-100 mx-12 text-justify">
                <p>Spiti- The middle-land between the lush green valleys and the cold mountain deserts of Himalayas. 
                    A Spiti Valley Trip is a traveler’s delight, thanks to the thrilling roadways and rustic landscapes. 
                    While most parts of India undergo unbearably high temperatures, 
                    the beauty of Spiti shines like a diamond under sun during peak summer months. 
                    The best time to visit Spiti is from June-September when the glacial lakes will welcome you in all their glory.
                    When you finally enter Spiti Valley you’ll realize that it is much more than just a picturesque place of Himalayas. 
                    There’s an abundance of rich Buddhist-Tibetan culture that resides in the serrated villages of Spiti. 
                    The Key Monastery is one fine example of architecture that makes you adore the concept of simplicity in Buddhism.</p>
            </div>
        </article>
    )
}