import Dikit from '../../images/stays/dikitling.jpg'
import Jichun from '../../images/stays/jichun1.jpg'
import Tanpa from '../../images/stays/tanpalacamp.jpg'
import Zanskar from '../../images/stays/karsha1.jpeg'

const List = [
    {
        id: "1",
        name: "Unreal Journey Guest House - Key",
        location: "Spiti Valley",
        address: "Key Village",
        rooms: "6",
        parking: "10",
        price: "2600",
        type: "Guest House",
        link: "https://www.airbnb.co.in/rooms/674292570462157461",
        image: Jichun,
    },
    {
        id: "2",
        name: "Unreal Journey Eco Lodge - Kibber",
        location: "Spiti Valley",
        address: "Kibber",
        rooms: "8",
        parking: "10",
        price: "3000",
        type: "Nature Lodge",
        link: "/wait",
        image: Dikit,
    },
    {
        id: "3",
        name: "Unreal Journey Camps - Key",
        location: "Spiti Valley",
        address: "Key Village",
        rooms: "9",
        parking: "10",
        price: "1000",
        type: "Nature Camps",
        link: "https://www.airbnb.co.in/rooms/688819695191322963",
        image: Tanpa,
    },
    {
        id: "4",
        name: "Unreal Journey Eco Lodge - Zanskar",
        location: "Zanskar",
        address: "Karsha",
        rooms: "8",
        parking: "5",
        price: "2000",
        type: "Eco Lodge",
        link: "/wait",
        image: Zanskar,
    },


]

export default function StayList() {
    return (
        <div>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full p-4">
                {List.map((l) => (
                    <div class="relative mx-auto w-full" key={l.id}>
                        <a href={l.link} class="relative inline-block duration-300 ease-in-out transition-transform transform hover:-translate-y-2 w-full">
                            <div class="shadow p-4 rounded-3xl bg-gray-200 dark:bg-gray-900 dark:text-white">
                                <div class="flex justify-center relative rounded-lg overflow-hidden sm:h-44 h-44">
                                    <div class="transition-transform duration-500 transform ease-in-out hover:scale-110 w-full">
                                        <div class="absolute inset-0 bg-black"> <img src={l.image} alt="img" /></div>
                                    </div>

                                    <div class="absolute flex justify-center bottom-0 mb-3">
                                        <div class="flex bg-white px-4 py-1 space-x-5 rounded-lg overflow-hidden shadow">
                                            <p class="flex items-center font-medium text-gray-800 dark:text-black">
                                                <svg class="w-5 h-5 fill-current mr-2 dark:text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480,226.15V80a48,48,0,0,0-48-48H80A48,48,0,0,0,32,80V226.15C13.74,231,0,246.89,0,266.67V472a8,8,0,0,0,8,8H24a8,8,0,0,0,8-8V416H480v56a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8V266.67C512,246.89,498.26,231,480,226.15ZM64,192a32,32,0,0,1,32-32H208a32,32,0,0,1,32,32v32H64Zm384,32H272V192a32,32,0,0,1,32-32H416a32,32,0,0,1,32,32ZM80,64H432a16,16,0,0,1,16,16v56.9a63.27,63.27,0,0,0-32-8.9H304a63.9,63.9,0,0,0-48,21.71A63.9,63.9,0,0,0,208,128H96a63.27,63.27,0,0,0-32,8.9V80A16,16,0,0,1,80,64ZM32,384V266.67A10.69,10.69,0,0,1,42.67,256H469.33A10.69,10.69,0,0,1,480,266.67V384Z"></path></svg>
                                                {l.rooms}
                                            </p>

                                            <p class="flex items-center font-medium text-gray-800 dark:text-black">
                                                <svg class="w-5 h-5 fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512"><path d="M423.18 195.81l-24.94-76.58C387.51 86.29 356.81 64 322.17 64H157.83c-34.64 0-65.34 22.29-76.07 55.22L56.82 195.8C24.02 205.79 0 235.92 0 271.99V400c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48v-16h256v16c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48V271.99c0-36.07-24.02-66.2-56.82-76.18zm-310.99-66.67c6.46-19.82 24.8-33.14 45.64-33.14h164.34c20.84 0 39.18 13.32 45.64 33.13l20.47 62.85H91.72l20.47-62.84zM80 400c0 8.83-7.19 16-16 16H48c-8.81 0-16-7.17-16-16v-16h48v16zm368 0c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm0-80.01v32H32v-80c0-26.47 21.53-48 48-48h320c26.47 0 48 21.53 48 48v48zM104.8 248C78.84 248 60 264.8 60 287.95c0 23.15 18.84 39.95 44.8 39.95l10.14.1c39.21 0 45.06-20.1 45.06-32.08 0-24.68-31.1-47.92-55.2-47.92zm10.14 56c-3.51 0-7.02-.1-10.14-.1-12.48 0-20.8-6.38-20.8-15.95S92.32 272 104.8 272s31.2 14.36 31.2 23.93c0 7.17-10.53 8.07-21.06 8.07zm260.26-56c-24.1 0-55.2 23.24-55.2 47.93 0 11.98 5.85 32.08 45.06 32.08l10.14-.1c25.96 0 44.8-16.8 44.8-39.95 0-23.16-18.84-39.96-44.8-39.96zm0 55.9c-3.12 0-6.63.1-10.14.1-10.53 0-21.06-.9-21.06-8.07 0-9.57 18.72-23.93 31.2-23.93s20.8 6.38 20.8 15.95-8.32 15.95-20.8 15.95z"></path></svg>
                                                {l.parking}
                                            </p>
                                        </div>
                                    </div>

                                    <span class="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10 bg-yellow-500 text-sm font-medium text-white dark:text-white select-none">
                                        Featured
                                    </span>
                                </div>

                                <div class="mt-4">
                                    <h2 class="font-medium text-base md:text-base text-gray-800 dark:text-white line-clamp-1" title={l.address}>
                                        {l.name}
                                    </h2>
                                    <p class="mt-2 text-sm text-gray-800 dark:text-white line-clamp-1" title={l.address}>
                                        {l.address}
                                    </p>
                                </div>

                                <div class="grid grid-cols-2 grid-rows-2 gap-4 mt-4">
                                    <p class="inline-flex flex-col xl:flex-row xl:items-center text-gray-800">
                                        <svg class="inline-block w-5 h-5 xl:w-4 xl:h-4 mr-3 fill-current dark:text-white text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M570.53,242,512,190.75V48a16,16,0,0,0-16-16H400a16,16,0,0,0-16,16V78.75L298.53,4a16,16,0,0,0-21.06,0L5.47,242a16,16,0,0,0,21.07,24.09L64,233.27V464a48.05,48.05,0,0,0,48,48H464a48.05,48.05,0,0,0,48-48V233.27l37.46,32.79A16,16,0,0,0,570.53,242ZM480,464a16,16,0,0,1-16,16H112a16,16,0,0,1-16-16V205.27l192-168,192,168Zm0-301.25-64-56V64h64ZM208,218.67V325.34A26.75,26.75,0,0,0,234.66,352H341.3A26.76,26.76,0,0,0,368,325.34V218.67A26.75,26.75,0,0,0,341.3,192H234.66A26.74,26.74,0,0,0,208,218.67ZM240,224h96v96H240Z"></path></svg>
                                        <span class="mt-2 xl:mt-0 dark:text-white">
                                            {l.type}
                                        </span>
                                    </p>
                                </div>

                                <div class="grid grid-cols-2 mt-2">
                                    <div class="flex items-center">
                                        <div class="relative">
                                            <div class="rounded-full w-6 h-6 md:w-8 md:h-8 bg-gray-200 dark:text-black"></div>
                                            <span class="absolute top-0 right-0 inline-block w-3 h-3 bg-primary-red rounded-full"></span>
                                        </div>

                                        <p class="ml-2 text-gray-800  dark:text-white line-clamp-1">
                                            {l.location}
                                        </p>
                                    </div>

                                    <div class="flex justify-end">
                                        <p class="inline-block font-semibold text-primary whitespace-nowrap leading-tight rounded-xl">
                                            <span class="text-sm uppercase">
                                                ₹
                                            </span>
                                            <span class="text-lg">{l.price}</span>/days
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                ))}

            </div>
            <div className="w-full mx-auto space-y-4 text-center pb-4">
                <a href="/wait" target="_blank" rel="noreferrer">
                    <button className="px-8 py-3 mt-10 text-lg font-semibold rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200">View More</button>
                </a>
            </div>
        </div>
    )
}