const statData = [
    {
        name: "Tours organised",
        numbers: "90+"
    },
    {
        name: "Happy Customers",
        numbers: "250+"
    },
    {
        name: "States currently operating",
        numbers: "2+"
    },
    {
        name: "Stays either partnered or owned",
        numbers: "4+"
    },
    {
        name: "Unreal Journey stays guests arrival all-time",
        numbers: "1300+"
    },
    {
        name: "Itinerarys designed",
        numbers: "50+"
    },

]

export default function Stats() {
    return (
        <section className="p-6 bg-gray-100 dark:bg-gray-700 dark:text-gray-100">
            <div className="text-4xl font-bold text-yellow-300 text-center mb-3">
                Fast Facts
            </div>

            <div className="container mx-auto grid justify-center grid-cols-2 text-center lg:grid-cols-3">
                {statData.map((sData) => (
                    <div className="flex flex-col justify-start m-2 lg:m-6">
                        <p className="text-4xl dark:text-white text-gray-500 font-bold leading-none lg:text-6xl">{sData.numbers}</p>
                        <p className="text-sm text-gray-400 sm:text-base">{sData.name}</p>
                    </div>
                ))}
            </div>

        </section>
    )
}