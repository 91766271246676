export default function BreifZanskar() {
    return (
        <article className="mx-auto px-6 py-24 space-y-12 dark:bg-gray-800 dark:text-gray-50">
            <div className="w-full mx-auto space-y-4 text-center">
                <p className="text-xs font-semibold tracking-wider uppercase">Zanskar Expedition</p>
                <h1 className="text-4xl font-bold leading-tight md:text-5xl text-yellow-300">Lets explore unreal Zanskar Valley and Ladakh</h1>

            </div>
            <div className="dark:text-gray-100 mx-12 text-justify">
                <p>A journey into the depths of Ladakh is a dream, a dream made of adventures, experiences & a trip of a lifetime! This cold desert of India always inspires us to explore more & more and so it is only right to head straight into the Zanskar Valley, a place that is a must on our bucket list!
                    One of the most unexplored regions of Ladakh, Zanskar holds beauty that lies beyond the eyes of the beholder. Our journey begins in Manali before we enter Lahaul valley the next day! A halt at Lahaul and a day to relax- calls for camping!
                    Moving ahead in our Journey to reach Zanskar, we take the offbeat road and cross Shinkula pass, situated at the height of 5,091 m.
                    In Zanskar, The No. 1 destination on our list is to experience the Phuktal Gompa, a beautiful institute perched in the mountains. We trek for a while to reach here.
                    Our ride continues from Padum and we head to Kargil after visiting the raw and mesmerising Shela waterfall! On our journey, we also explore the Sani village, head to Pensi La Pass and Rangdum Monastery!
                    From Kargil, we head to Leh, locally explore the city and it’s famous gems. And a trip to Ladakh isn’t complete until we ride across the highway of Manali-Leh! Cross passes, valleys and plains to reach Manali.
                    This backpacking riding trip takes us across the beautiful varied landscapes of Ladakh! Join us if you know that adventure is your lifetime partner!</p>
            </div>
        </article>
    )
}