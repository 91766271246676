// Hero Section

export default function Hero() {
    return (
        <div className="relative bg-white overflow-hidden sm:h-full dark:bg-gray-800">
            <div className="max-w-7xl mx-auto ">
                <div className="relative z-10  pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 ">
                    {/* <svg
                        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                       
                    </svg> */}
                    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-5xl md:text-6xl">
                                <span className="block xl:inline">Explore the unexplored with</span>{' '}
                                <span className="block text-yellow-500 xl:inline">Unreal Journey</span>
                            </h1>
                            <p className="mt-3 text-sm pr-16 text-gray-500 dark:text-white sm:mt-5 sm:text-base sm:max-w-xl sm:mx-auto md:mt-5 md:text-base lg:mx-0 ">
                                We know everyone has a bucket list and we mostly hesitate when it comes to the budget of the trip,
                                we are trying to bridge that gap to make your dreams come true.
                            </p>
                            {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                
                                <div className="mt-3 sm:mt-0 sm:ml-3">
                                    <a
                                        href="/"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
                                    >
                                        Explore
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                    src="https://media.istockphoto.com/photos/namgyal-tsemo-gompa-in-leh-ladakh-india-picture-id916575772?b=1&k=20&m=916575772&s=170667a&w=0&h=7Yhd3iif1SYHxhka6mG9RmTYizgonm2D1DHcqyGpEDQ="
                    alt=""
                />
            </div>
        </div>
    )
}