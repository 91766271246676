export default function Terms() {
    return (
        <div className="dark:bg-gray-800 bg-gray-100 text-black dark:text-gray-300">
            <div className="p-20">
                <h1 className="text-center text-4xl my-6 font-semibold ">Terms & Conditions</h1>
                <ul className=" list-decimal ml-6 md:text-base text-xs">
                    <li>Full Payment of the trip cost must be made before the trip begins. Pending Payments may eventually lead to the Cancellation of the trip.</li>
                    <li>The IDs shall all be verified before boarding. No boarding shall be entertained without a valid Govt. ID.</li>
                    <li>The Transfer of the bookings is not permitted. Only the names mentioned at the time of confirmation shall be allowed to travel.</li>
                    <li>No refunds shall be made towards any inclusion(s) not availed by the Client.</li>
                    <li>Travelers must take care of their luggage & belongings. The management shall not be responsible for any damage or any missing items along the tour.</li>
                    <li>The time of Departure is stated & fixed. All travelers must update their status with the Trip coordinator(s), & report at the pickup point 30 mins prior to the scheduled departure.</li>
                    <li>The Air Conditioning will be switched off in the hills. Also, during the trip, it shall be the Driver’s discretion to put off the AC as & when required, considering the travelers’ safety & ease of travel along uneven & dangerous routes.</li>
                    <li>Our time of departure is fixed must depart by the stated time & keep their status updated with the trip coordinator(s). Anyone missing the bus shall not be eligible for any refunds. We shall call you twice before the schedule departure.</li>
                    <li>Drinking & Smoking are strictly prohibited along the tour due to health & safety concerns. Our tours involve physically demanding activities such as trekking, camping at high altitudes and we recommend for you to be in the right shape to make most of them.</li>
                    <li>No act of misconduct or indiscipline shall be tolerated on the tours. We are a cordial travel community and we aspire to bring to you a hassle free and memorable experience.</li>
                    <li>Unreal Journey shall not be responsible for any delays or alterations in the program or indirectly incurred expenses in cases such as Natural Hazards, accident, breakdown of machinery, weather conditions, landslides, political closure or any untoward incidents.</li>
                    <li>We do not provide any insurance policy to cover for the expenditure on sickness or accidents or losses incurred due to theft or other reasons.</li>
                    <li>Numerous factors such as weather and road conditions the physical ability of participants etc. may bring alteration in the itinerary. We reserve the right to make necessary changes in the schedule in the interest of safety, comfort and general well-being!</li> </ul>

            </div>
        </div>
    )
}