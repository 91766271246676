import ICON1 from '../images/icons/car.png'
import ICON2 from '../images/icons/travel.png'
import ICON3 from '../images/icons/trek.png'
import ICON4 from '../images/icons/backpack.png'
import ICON5 from '../images/icons/camp.png'
import ICON6 from '../images/icons/family.png'

const imageData = [
    {
        name: 'Spiti Valley',
        icon: ICON1,
    },
    {
        name: 'Leh Ladakh',
        icon: ICON2,
    },
    {
        name: 'Trekking',
        icon: ICON3,
    },
    {
        name: 'Backpacking Trips',
        icon: ICON4,
    },
    {
        name: 'Weekend Trips',
        icon: ICON5,
    },
    {
        name: 'Family Trips',
        icon: ICON6,
    },
]


export default function ExploreCard() {
    return (
        <div className='dark:bg-gray-800 bg-white grid sm:grid-cols-6 sm:grid-row-1 grid-cols-3 2xl:grid-rows-2 gap-4 place-content-center'>
            {imageData.map((iData) => (
                <div key={iData.name} className="text-center m-8 drop-shadow-2xl">
                    <div className="sm:w-32 sm:h-32 w-12 h-12 ">
                        <div><img src={iData.icon} alt='Explore Icon' className='block mx-auto ' /></div>
                        <span className='text-center text-black dark:text-white'>
                            {iData.name}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}