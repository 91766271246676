export default function StayHero() {
	return (
		<section className="dark:bg-gray-800 dark:text-gray-100">
			<div className="container mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-3xl">
				<h1 className="text-4xl font-bold leading-none sm:text-5xl">Unreal Journey
					<span className="text-yellow-400"> Stays</span>
				</h1>
				<p className="px-8 mt-8 mb-12 text-lg">Stay at the most exquisite properties around India, we offer one of the most secluded and peaceful properties around the country for travellers looking for a peace and calming stay!</p>
				<div className="flex flex-wrap justify-center">
					<a href="/contact">
						<button className="px-8 py-3 mt-10 text-lg font-semibold rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200">Contact Us</button>
					</a>
				</div>
			</div>
		</section>
	)
}